import React, { useEffect } from 'react';
import { useBlogPosts } from '../hooks/useBlogPosts';
import { generateSitemap } from '../utils/sitemap';

const Sitemap = () => {
  const { posts, loading, error } = useBlogPosts();

  useEffect(() => {
    if (!loading && !error && posts.length > 0) {
      const sitemap = generateSitemap(posts);
      
      // Create a Blob with the sitemap content
      const blob = new Blob([sitemap], { type: 'application/xml' });
      const url = URL.createObjectURL(blob);
      
      // Create a link element and trigger download
      const link = document.createElement('a');
      link.href = url;
      link.download = 'sitemap.xml';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      // Clean up the URL object
      URL.revokeObjectURL(url);
    }
  }, [posts, loading, error]);

  if (loading) {
    return <div>Gerando sitemap...</div>;
  }

  if (error) {
    return <div>Erro ao gerar sitemap: {error}</div>;
  }

  return <div>Sitemap gerado com sucesso!</div>;
};

export default Sitemap;